import { CustomSnackbar } from "./CustomSnackbar";
import NoLogo from "../assets/images/featured-job/no-logo.png";
import UserAvatar from "../assets/images/avatar.png";

import { Link } from "react-router-dom";

export const apiUrl = "https://jobseekerspage.com/backend/api/web";
export const dataUrl = "https://jobseekerspage.com/backend/data";

export const capitalize = (str) =>
  `${str.charAt(0).toUpperCase() + str.substr(1).toLowerCase()}`;

export const sortFn = (el1, el2, sortKey = "display_order") =>
  el1[sortKey] > el2[sortKey] ? 1 : -1;

export const preferredDeliveryCategoryId = [52];

export const getIds = (array, column) => {
  const ids = [];
  array.forEach((element) => {
    ids.push(element?.additional_fields?.[column]);
  });
  return ids;
};

export function isValidURL(string) {
  var res = string.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)/g
  );
  return res !== null;
}

export const isValidMobile = (val) => {
  if (/^\d{10}$/.test(val)) {
    return true;
  } else {
    return false;
  }
};

export function humanReadableSize(bytes) {
  let size = parseInt(bytes);
  for (let unit of ["b", "Kb", "Mb", "Gb"]) {
    if (size < 1024) return `${size.toFixed(1)} ${unit}`;
    size /= 1024.0;
  }
}

export function scrollWindow(top = 100) {
  return window.scrollTo({
    top,
    behavior: "smooth",
  });
}

export const imageUrl = (
  imageName,
  prefix = "contents",
  sizePrefix = "120x120-"
) => `${dataUrl}/${prefix}/${sizePrefix}${imageName}`;

export const getMenusByCategory = (menus, categoryName) =>
  menus.filter((menu) => menu.category === categoryName);

export const productImageUrl = (
  url,
  prefix = "products",
  id = "1",
  sizePrefix = "120x120-"
) => `${dataUrl}/${prefix}/${id}/${sizePrefix}${url}`;

export const testimonialimageUrl = (url, prefix = "products") =>
  `${dataUrl}/${prefix}/${url}`;

export const convertToSlug = (text) => {
  return (
    text &&
    text
      ?.toLowerCase()
      ?.replace(/[^\w ]+/g, "")
      ?.replace(/ +/g, "-")
  );
};

// get time stamp in seconds
export function getTimestampInSeconds() {
  return Math.floor(Date.now() / 1000);
}

export const getContentByName = (contents, name, defaultValue) =>
  contents.find((content) => content.name === name) || defaultValue;

export const getAssetsByName = (assets, name, defaultValue) =>
  assets.find((asset) => asset.name === name) || defaultValue;

export const getCategoryByName = (categories, name) => {
  return categories.find(
    (category) => category.name.toLowerCase() === name.toLowerCase()
  );
};

export const getCategoriesByParentName = (categories, parentName) => {
  let parentId = getCategoryByName(categories, parentName)?.id;
  return categories.filter(
    (category) => parseInt(category.parent_id) === parseInt(parentId)
  );
};

export const getJobLocationById = (id, locations) => {
  const parsedId = parseInt(id);

  if (!id) {
    return "India";
  }

  if (isNaN(parsedId)) {
    // If id is not a number after parseInt, return 'id'
    return id;
  } else {
    // Otherwise, proceed to find the location with the matching id
    return (
      locations?.find((location) => location?.id === parsedId)?.name || "India"
    );
  }
};

export const getParsedJson = (json, defaultValue = "{}") => {
  let parsedJson = defaultValue;

  try {
    if (isJsonString(json)) {
      parsedJson = JSON.parse(json ?? defaultValue);
    }

    if (isJsonString(parsedJson)) {
      parsedJson = JSON.parse(parsedJson ?? defaultValue);
    }
  } catch (error) {
    parsedJson = JSON.parse(defaultValue);
  }

  return parsedJson;
};

export function isPlanExpired(expiryDate) {
  // Parse the expiry date string into a Date object
  const expiration = new Date(expiryDate);
  // Get the current date and time
  const now = new Date();
  // Check if the current date is after the expiry date
  return now > expiration;
}

export const filterByCategoryName = (contents, categoryName, defaultValue) =>
  contents.filter((content) => content.category === categoryName) ||
  defaultValue;

export const getCategoryLink = (category) =>
  `/category/${category.id}/${category.url_slug}/1`;

export const getContentByCategoryName = (
  contents,
  categoryName,
  defaultValue
) =>
  contents.filter((content) => content.category === categoryName) ||
  defaultValue;

export const getSettingByKey = (settings, keyName, defaultValue) =>
  settings?.find((setting) => setting.key === keyName) || defaultValue;

export function searchJobs(jobs, departments = ["All"], locations = ["All"]) {
  // if (departments.includes('All') || locations.includes('All')) {
  //   return jobs;
  // }

  // if (departments.length === 0 && locations.length === 0) {
  //   return jobs;
  // }

  // if (departments.length > 0 && locations.length === 0) {
  //   return jobs.filter((job) => departments.includes(job.department));
  // }

  // if (departments.length === 0 && locations.length > 0) {
  //   return jobs.filter((job) => locations.includes(job.location));
  // }

  // if All departments and All locations are selected

  return jobs.filter(
    (job) =>
      (departments.includes("All") || departments.includes(job.department)) &&
      (locations.includes("All") || locations.includes(job.location))
  );
}

export const openInNewWindow = (url) => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

export const openUrlNewWindow = (menu) => {
  const url = menu?.url;
  if (menu?.open_in_new_window === "yes") {
    openInNewWindow(url);
  }
};

// getting splitted array from string by setting key
export function getSplittedArrayBySettingsKey(keyValue, settings) {
  const array = getSettingByKey(settings, keyValue)?.value;
  return keyValue ? toArray(array, "\n") : [];
}

export const getTestimonialByName = (
  clienttestimonials,
  company,
  defaultValue
) =>
  clienttestimonials.find(
    (clienttestimonial) => clienttestimonial.company === company
  ) || defaultValue;

export const getTestimonialByCompanyName = (clienttestimonials, companyName) =>
  clienttestimonials.filter(
    (clienttestimonial) => clienttestimonial.company === companyName
  );

export function isUserEmployer(customer) {
  return customer?.customer_type === 1;
}

export function isEmailVerified(data) {
  return data?.customer?.is_email_verified === 1;
}

export const getCategoryByParentId = (categories, parentId) =>
  categories.filter(
    (category) => parseInt(category.parent_id) === parseInt(parentId)
  );

// it is used to make the given string into array by splitting using the given seperator.
export const toArray = (value, seperator = ",") => {
  let arr = [];

  if (value) {
    arr = value
      .split(seperator)
      .filter(Boolean)
      .map((v) => v.trim())
      .filter((v) => v !== "");
  }
  return arr;
};

export function getRandomArrayItems(array, count) {
  const shuffled = array.sort(() => 0.5 - Math.random());
  return shuffled.slice(0, count);
}

// Divide array into chunks
export function sliceIntoChunks(arr, chunkSize) {
  const res = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    res.push(chunk);
  }
  return res;
}

// Array of object accending order => Name A to Z
export function toAccendingOrder(array) {
  return array?.sort((a, b) => {
    return a.name > b.name ? 1 : -1;
  });
}

// check img exists or not in the given url
export const checkImgExists = (url) => {
  var img = new Image();
  img.src = url;
};

//  check img exists or not in the given url
export function imageExists(image_url) {
  var http = new XMLHttpRequest();

  http.open("HEAD", image_url, false);
  http.send();

  return http.status !== 404;
}

// it is used to get the image url from the given html.
// export const getImageUrl = (html) => {
//     let img = html.match(/<img.*?src="(.*?)"/);
//     // let regex = /src="(.*?)"/i;
//     // let match = regex.exec(html);
//     return match[1];
// }

export const getUrlParams = (paramName, paramValue) => {
  let url = document.location;
  var urlObj = new window.URL(url);
  urlObj.searchParams.set(paramName, paramValue);

  return urlObj.searchParams.toString();
};

export const removeUrlParam = (paramName) => {
  let url = document.location;
  var urlObj = new window.URL(url);
  urlObj.searchParams.delete(paramName);
  return urlObj.searchParams.toString();
};

export const getAppliedFilters = (paramName) => {
  let url = document.location;
  var urlObj = new window.URL(url);
  return urlObj.searchParams.get(paramName);
};

export const getFilterPath = (params, prefix = "jobs") => {
  return `/${prefix}?${params}`;
};

export const getImageUrl = (html) => {
  let img = html.match(/<img.*?src="(.*?)"/);
  return img[1];
};

export function getFirstCharacter(fullName) {
  let fname = fullName.replace(/\s+/g, " ").trim();
  fname = fname.split(" ");
  let firstNames = [];
  if (fname[0]) {
    if (fname[0][0]) {
      firstNames.push(fname[0][0]);
    }
  }
  if (fname[1]) {
    if (fname[1][0]) {
      firstNames.push(fname[1][0]);
    }
  }
  return firstNames.join("").toUpperCase();
}

export const getFirstChar = (word) => {
  return word?.charAt(0).toUpperCase() ?? "";
};

export const renderMonth = (value) => {
  switch (value) {
    case "01":
      return "Jan";
    case "02":
      return "Feb";
    case "03":
      return "Mar";
    case "04":
      return "Apr";
    case "05":
      return "May";
    case "06":
      return "Jun";
    case "07":
      return "Jul";
    case "08":
      return "Aug";
    case "09":
      return "Sep";
    case "10":
      return "Oct";
    case "11":
      return "Nov";
    case "12":
      return "Dec";
    default:
      return "";
  }
};

export function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export const showErrorMessage = (error) => {
  if (error?.includes("Your request was made with invalid credentials")) {
    CustomSnackbar.error(
      "To proceed with your request, kindly log in to your account."
    );
  } else {
    CustomSnackbar.error(error);
  }
};

export const apiErrorHandler = (err) => {
  let error = "Something went wrong.";

  if (err?.response?.data && err?.response?.data[0]?.message) {
    error = err?.response?.data[0]?.message;
  } else if (err?.response?.data?.message) {
    error = err?.response?.data?.message;
  } else {
    error = err?.message;
  }

  if (error) {
    showErrorMessage(error);
  }
};

export const getTotalVacancies = (job) => {
  const numOfVacancies = job?.total_number_of_vacancies;

  if (numOfVacancies > 1) {
    return `${numOfVacancies} Vacancies`;
  } else if (numOfVacancies > 0) {
    return `${numOfVacancies} Vacancy`;
  } else {
    return "No Vacancy";
  }
};

export const getJobExperiences = (job, suffix = "Years") => {
  const minExp = parseInt(job?.minimum_experience_required);
  const maxExp = parseInt(job?.maximum_experience_required);

  if (minExp === 0 && maxExp === 0) {
    return "Exp Not required";
  }

  if (minExp === 0) {
    return "Exp Not required";
  }

  if (minExp <= 1) {
    suffix = "Year";
  }

  return `${minExp} ${suffix}`;
};

export const getSalaryAmount = (job, suffix = "month", showIcon = true) => {
  const minSalary =
    typeof job !== "string" ? parseInt(job?.minimum_salary_per_month) : job;

  return (
    <>
      {showIcon && <i className='uil uil-wallet'></i>}{" "}
      {`₹${minSalary} / ${suffix}`}
    </>
  );
};

export const getCompanyLogo = (logo) => {
  if (logo) {
    return `${dataUrl}/jobcompanies/120x120-${logo}`;
  } else {
    return NoLogo;
  }
};

export const getUserPic = (pic, dimension = "120x120-") => {
  if (pic) {
    return `${dataUrl}/customers/${dimension}${pic}`;
  } else {
    return UserAvatar;
  }
};

export const getKeywords = (list) => {
  let listArr = toArray(list, ",");

  if (listArr?.length) {
    return listArr.map((h, index) => (
      <li className='list-inline-item' key={"keywords-" + index}>
        <Link to='#' className='primary-link text-muted text-capitalize'>
          {h}
        </Link>
        {index === listArr.length - 1 ? "" : ","}
      </li>
    ));
  }

  return <span>Not available</span>;
};

export const getJobsByType = (jobs, type) =>
  jobs?.filter((job) => job?.job_type?.toLowerCase() === type) || [];

export const getUnreadNotifications = (data) => {
  if (Array.isArray(data)) {
    return data?.filter((item) => item.is_viewed === 0);
  }

  return [];
};

export function sumObjectValues(obj) {
  let sum = 0;
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      sum += obj[key];
    }
  }
  return sum;
}

export function validateUsername(username) {
  // Check if the username is a string
  if (typeof username !== "string") {
    return "Username must be a string.";
  }

  // Check if the username is between 6 and 30 characters long
  if (username.length < 6 || username.length > 30) {
    return "Username must be between 6 and 30 characters long.";
  }

  // Check if the username contains only alphanumeric characters and underscores
  var validCharacters = /^[a-zA-Z0-9_]+$/;
  if (!validCharacters.test(username)) {
    return "Username must contain only alphanumeric characters and underscores.";
  }

  // If all checks pass, the username is valid
  return "";
}

export function validateMobileNumber(mobileNumber) {
  if (mobileNumber.length !== 10) {
    return "Mobile number must be 10 digits long.";
  }

  if (!isValidMobile(mobileNumber)) {
    return "Mobile number must contain only numbers.";
  }

  return "";
}

export const filterDuplicateArray = (array, key = "value") => {
  if (!array?.length) {
    return [];
  }

  const seen = new Set();
  const filteredArray = array?.filter((item) => {
    // Determine the value to check based on whether a key is provided
    const identifier = key && typeof item === "object" ? item[key] : item;

    if (!seen.has(identifier)) {
      seen.add(identifier);
      return true;
    }
    return false;
  });
  return filteredArray;
};

function capitalizeString(str) {
  // First, convert the entire string to lowercase.

  str = str.toLowerCase();

  // Then, replace the first character of each word with its uppercase counterpart.
  return str.replace(/\b\w/g, function (char) {
    return char.toUpperCase();
  });
}

export const getDropdownOptions = (
  array,
  objStructure = { label: "name", value: "id" }
) => {
  let data = [];

  if (array && array.length > 0) {
    array.forEach((e) => {
      if (e[objStructure?.label]) {
        let label = capitalizeString(e[objStructure?.label]?.trim()) || "";

        data.push({
          label,
          value: e[objStructure?.value],
        });
      }
    });
  }

  return data;
};

export const getWorkExpText = (total_work_experience) => {
  return `${total_work_experience} ${
    total_work_experience !== "Fresher" ? "years" : ""
  }`;
};

export function loggedInWithin30Days(lastLoginDateString) {
  const lastLoginDate = new Date(lastLoginDateString);
  const currentDate = new Date();

  const difference = currentDate - lastLoginDate;
  const differenceInDays = difference / (1000 * 3600 * 24);

  return differenceInDays <= 30;
}

export function getFileExtension(fileName) {
  if (fileName) {
    const a = fileName?.split(".");
    if (a.length === 1 || (a[0] === "" && a.length === 2)) {
      return "";
    }
    return a.pop();
  }
  return "";
}

export const isInsufficientCreditError = (message) =>
  message === "No credits available." ||
  message === "Your credits are expired" ||
  message === "You do not have enough credits to see this candidate profile.";

export const buyCreditsAlert = (
  message = "No credits available.",
  redirectPath
) => {
  const conf = window.confirm(`${message} Do you want to buy some credits?`);
  if (conf) {
    if (redirectPath) {
      localStorage.setItem("redirect_path_after_buy_credits", redirectPath);
    }

    window.open("/pricing", "_self");
  }
};

export function getCreditText(value) {
  return value > 1 ? "credits" : "credit";
}

export const downloadPdfFromBinaryData = (data, fileName, fileExt = "pdf") => {
  try {
    // Assuming the PDF data is received in binary format ('arraybuffer')
    const pdfBlob = new Blob([data], { type: "application/pdf" });

    // Create a link element
    const link = document.createElement("a");

    // Create a URL for the blob
    const pdfURL = window.URL.createObjectURL(pdfBlob);

    // Set link properties
    link.href = pdfURL;
    link.download = `${fileName}.${fileExt}`;

    // Append the link to the body (usually not needed to be visible)
    document.body.appendChild(link);

    // Programmatically trigger the download
    link.click();

    // Clean up by revoking the object URL and removing the link
    window.URL.revokeObjectURL(pdfURL);
    document.body.removeChild(link);
  } catch (error) {
    showErrorMessage("Unable to download document!");
    console.log("error::", error);
  }
};

export const decodeHtmlEntities = (str) => {
  const txt = document.createElement("textarea");
  txt.innerHTML = str;
  return txt.value;
};

export const customerId_local_storage_key = "jobseekerspage_customerId";
export const authKey_local_storage_key = "jobseekerspage_authToken";
export const cartData_local_storage_key = "jobseekerspage_cart_data";
export const userInfo_local_storage_key = "jobseekerspage_userInfo";
export const recently_viewed_item_key = "jobseekerspage_recently_viewed_jobs";
export const redirect_url_key = "jobseekerspage_redirect_url";
